
<template>
    <div>
        <Header></Header>
        <div class="main_content">
            <div class="middle_content">
                <div style="width: 100%;">
                    <div class="pageHeader">
                        <el-page-header @back="goBack" content="详情"></el-page-header>
                        <el-tabs v-model="activeName" class="mt10" v-if="viewType == 1">
                            <el-tab-pane label="课程练习" name="video"></el-tab-pane>
                            <el-tab-pane v-if="memberPhotoReport" label="课程附件" name="report"></el-tab-pane>
                            <el-tab-pane v-else label="" name="report"></el-tab-pane>
                        </el-tabs>
                        <el-tabs v-model="activeName"  class="mt10" v-if="viewType == 2">
                            <el-tab-pane label="课程附件" name="report"></el-tab-pane>
                        </el-tabs>
                    </div>
                    <!-- 视频内容 -->
                    <div class="edu-main" v-show="activeName == 'video'">
                        <div class="container-player">
                        <video id="myVideo" class="video-js" poster=""></video>
                    </div>
                     <!-- 标题 -->
                     <div class="title-main">
                        <div class="title ">{{ obj.videoTitle }}</div>
                        <button>
                            <i class="iconfont icon-fenxiang7"></i>
                            分享
                        </button>
                     </div>
                     <!-- 视频详情 -->
                     <div class="edu-detail">
                        <div class="edu-detail-item" v-if="obj.videoList.length>0">
                            <i class="iconfont icon-wenjianjia1"></i>
                            共 {{obj.videoList.length}} 课时
                        </div>
                        <div class="edu-detail-item">
                            <i class="iconfont icon-bofang3"></i>
                            {{(obj.viewedNum || 0)>10000?obj.viewedNum/10000+'万':(obj.viewedNum || 0)}}
                        </div>
                        <div class="edu-detail-item cup" @click="fabulous('video')">
                            <i class="iconfont icon-aixin3 red" v-if="videoMsg.praiseStatus"></i>
                            <i class="iconfont icon-aixin2" v-else></i>
                            {{(videoMsg.praiseCount || 0)>10000?videoMsg.praiseCount/10000+'万':(videoMsg.praiseCount || 0)}}人点赞
                        </div>
                        <div class="edu-detail-item cup" @click="show_model('video')">
                            <i class="iconfont icon-shoucang red" v-if="videoMsg.collectionStatus"></i>
                            <i class="iconfont icon-31shoucang" v-else></i>
                            {{(videoMsg.collectionCount || 0)>10000?videoMsg.collectionCount/10000+'万':(videoMsg.collectionCount || 0)}}人收藏
                        </div>
                     </div>
                     <!-- 介绍 -->
                     <div class="detail-desc">			
                       {{ obj.videoDetails || '' }}
                     </div>
                     <!-- 价格 -->
                     <div class="column-price-card">
                        <!-- 价格购买 -->
                        <div class="column-buy-btn single" v-if="pay_ok">
                            立即购买
                            <b>¥{{ this.obj.price }}</b>
                        </div>
                        <!-- vip购买 -->
                        <!-- <div class="column-buy-btn vip">
                            VIP会员免费
                        </div> -->
                     </div>
                    </div>

                    <!-- 报告详情 -->
                    <div class="edu-main" v-show="activeName == 'report'">
                        <div class="container-player">
                            <img :src="memberPhotoReport && memberPhotoReport.photoUrl?  memberPhotoReport.photoUrl : ''" alt="">
                        </div>
                        <!-- 标题 -->
                        <div class="title-main">
                            <div class="title ">{{videoReport&&videoReport.reportText ?  videoReport.reportText : ''}}</div>
                            <div>
                                <el-button type="primary" plain @click="preview_report(memberPhotoReport.reportUrl,memberPhotoReport.id)">浏览</el-button>
                                <el-button type="success" plain @click="download_report(memberPhotoReport.reportUrl,memberPhotoReport.id)">下载</el-button>
                            </div>
                        </div>
                        <!-- 详情 -->
                        <div class="edu-detail">
                            <div class="edu-detail-item cup" @click="fabulous('')">
                                <i class="iconfont icon-aixin3 red" v-if="reportMsg.praiseStatus"></i>
                                <i class="iconfont icon-aixin2" v-else></i>
                                {{(reportMsg.praiseCount || 0)>10000?reportMsg.praiseCount/10000+'万':(reportMsg.praiseCount || 0)}}人点赞
                            </div>
                            <div class="edu-detail-item cup" @click="show_model('')">
                                <i class="iconfont icon-shoucang red" v-if="reportMsg.collectionStatus"></i>
                                <i class="iconfont icon-31shoucang" v-else></i>
                                {{(reportMsg.collectionCount || 0)>10000?reportMsg.collectionCount/10000+'万':(reportMsg.collectionCount || 0)}}人收藏
                            </div>
                        </div>
                        <!-- 介绍 -->
                        <div class="detail-desc">			
                            {{ videoReport&&videoReport.profiles ? videoReport.profiles : '' }}
                        </div>
                        <!-- 价格 -->
                        <div class="column-price-card">
                            <!-- 价格购买 -->
                            <!-- <div class="column-buy-btn single">
                                立即购买
                                <b>¥59</b>
                                <del>¥99</del>
                            </div> -->
                            <!-- vip购买 -->
                            <!-- <div class="column-buy-btn vip">
                                开通VIP会员免费看
                            </div> -->
                        </div>
                    </div>
                    
                    <!-- 视频tab -->
                    <div class="edu-tab" v-if="activeName == 'video'">
                        <div class="edu-tab-item" :class="{active:activeTab == 0}" @click="activeTab = 0">课程概述</div>
                        <div class="edu-tab-item" :class="{active:activeTab == 1}" @click="activeTab = 1">互动留言({{ videoMsg.commentCount || 0}})</div>
                    </div>
                    <!-- report tab -->
                    <div class="edu-tab" v-if="activeName == 'report'">
                        <div class="edu-tab-item" :class="{active:activeTab == 0}" @click="activeTab = 0">报告概述</div>
                        <div class="edu-tab-item" :class="{active:activeTab == 1}" @click="activeTab = 1">互动留言({{viewType == 1 ? reportMsg.commentCount || 0 : videoMsg.commentCount || 0}})</div>
                    </div>

                    <!-- 课程概述 -->
                    <div class="edu-detail-wapper" v-if="activeTab == 0 && activeName == 'video'">
                        <div class="edu-detail-content">
                            <div class="content-title">课程概述</div>
                            <div class="content-html" v-html="tran(obj.content)"></div>
                        </div>
                    </div>
                    <!-- 报告概述 -->
                    <div class="edu-detail-wapper" v-if="activeTab == 0 && activeName == 'report'">
                        <div class="edu-detail-content">
                            <div class="content-title">报告概述</div>
                            <div class="content-html" v-html="tran(memberPhotoReport.content)"></div>
                        </div>
                    </div>
                    
                    <!-- 视频 互动评论 -->
                    <div class="edu-comment-wrapper" v-if="activeTab == 1 && activeName == 'video'">
                        <div class="comment-title">
                            全部留言({{ videoMsg.commentCount || 0}})
                        </div>
                        <!-- 发表评论 -->
                        <div class="comment_box">
                            <input v-model="comment_input" placeholder="请发表友善的评论" @keyup.enter="publish(comment_input)" />
                            <el-button type="danger" round @click="publish(comment_input)">发表回复</el-button>
                        </div>
                        <!-- 评论列表 -->
                        <div class="comment-list">
                            <div class="comments-detail" v-for="(item,index) in video_list">
                                <div class="comments-detail-user">
                                    <img :src="item.headUrl || ''" alt="">
                                    <span class="comments-detail-user-info">{{item.evaluatorsName || ''}}</span>
                                </div>
                                <div class="comments-detail-content">{{item.content}}</div>
                                <div class="comments-detail-opt">
                                    <div class="comments-detail-opt-item">
                                        {{item.releaseTime}}
                                    </div>
                                    <div class="comments-detail-opt-item" @click="reply(item,video_list)">
                                        <i class="iconfont icon-xiaoxi4"  v-if="!item.showReply"></i>
                                        <i class="iconfont icon-xiaoxi4" v-if="item.showReply" style="color: var(--color);"></i>
                                        <span v-if="!item.showReply">回复</span>
                                        <span v-if="item.showReply" style="color: var(--color);">取消回复</span>
                                    </div>
                                    <div class="comments-detail-opt-item delcomment" v-if="item.evaluatorsId == userInfo.id"  @click="delEva(item.id,index,video_list)">
                                        <i class="iconfont icon-lajixiang"></i>
                                        <span >删除</span>
                                    </div>
                                </div>
                                <!-- 发表评论 -->
                                <div class="comment_box ml32" v-if="item.showReply">
                                    <input v-model="content" :placeholder="nickName" @keyup.enter="publish(content,item)" />
                                    <el-button type="danger" round @click="publish(content,item)">发表回复</el-button>
                                </div>
                                <!-- 查看全部评论 -->
                                <div class="fetch-more-comment" v-if="item.countReply>0 && !item.replyList" @click="moreComment(item.id,1)">
                                    查看全部{{item.countReply}}条评论
                                    <i class="iconfont icon-xiangxia"></i>
                                </div>
                                <div class="sub-comments" v-if="item.replyList && item.replyList.length>0">
                                    <div v-for="(item2,index2) in item.replyList" :key="index2" class="sub-comments-item">
                                        <div class="comments-detail-user">
                                            <img :src="item2.headUrl" alt="">
                                            <span class="user-name">{{item2.evaluatorsName || ''}}</span>
                                            <!-- <span class="user-tips">讲师</span> -->
                                        </div>
                                        <div class="comments-detail-content">{{item2.content}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 报告 互动评论 -->
                    <div class="edu-comment-wrapper" v-if="activeTab == 1 && activeName == 'report'">
                        <div class="comment-title">
                            全部留言({{viewType == 1 ? reportMsg.commentCount || 0 : videoMsg.commentCount || 0}})
                        </div>
                        <!-- 发表评论 -->
                        <div class="comment_box">
                            <input v-model="report_content" placeholder="请发表友善的评论" @keyup.enter="report_publish(report_content)" />
                            <el-button type="danger" round @click="report_publish(report_content)">发表回复</el-button>
                        </div>
                        <!-- 评论列表 -->
                        <div class="comment-list">
                            <div class="comments-detail" v-for="(item,index) in report_list">
                                <div class="comments-detail-user">
                                    <img :src="item.headUrl || ''" alt="">
                                    <span class="comments-detail-user-info">{{item.evaluatorsName || ''}}</span>
                                </div>
                                <div class="comments-detail-content">{{item.content}}</div>
                                <div class="comments-detail-opt">
                                    <div class="comments-detail-opt-item">
                                        {{item.releaseTime}}
                                    </div>
                                    <div class="comments-detail-opt-item" @click="report_click_user(item,video_list)">
                                        <i class="iconfont icon-xiaoxi4"  v-if="!item.showReply"></i>
                                        <i class="iconfont icon-xiaoxi4" v-if="item.showReply" style="color: var(--color);"></i>
                                        <span v-if="!item.showReply">回复</span>
                                        <span v-if="item.showReply" style="color: var(--color);">取消回复</span>
                                    </div>
                                    <div class="comments-detail-opt-item delcomment" v-if="item.evaluatorsId == userInfo.id"  @click="delEva(item.id,index,video_list)">
                                        <i class="iconfont icon-lajixiang"></i>
                                        <span >删除</span>
                                    </div>
                                </div>
                                <!-- 发表评论 -->
                                <div class="comment_box ml32" v-if="item.showReply">
                                    <input v-model="content" :placeholder="nickName" @keyup.enter="report_publish(content,item)" />
                                    <el-button type="danger" round @click="report_publish(content,item)">发表回复</el-button>
                                </div>
                                <!-- 查看全部评论 -->
                                <div class="fetch-more-comment" v-if="item.countReply>0 && !item.replyList" @click="moreComment(item.id,2)">
                                    查看全部{{item.countReply}}条评论
                                    <i class="iconfont icon-xiangxia"></i>
                                </div>
                                <div class="sub-comments" v-if="item.replyList && item.replyList.length>0">
                                    <div v-for="(item2,index2) in item.replyList" :key="index2" class="sub-comments-item">
                                        <div class="comments-detail-user">
                                            <img :src="item2.headUrl" alt="">
                                            <span class="user-name">{{item2.evaluatorsName || ''}}</span>
                                        </div>
                                        <div class="comments-detail-content">{{item2.content}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <div class="middle_right" v-if="obj.videoList.length>0">
                    <!-- 课程列表 -->
                    <div class="right-info">
                        <div class="title">课程目录</div>
                        <div class="info-content">
                            <div class="info-content-hidden">
                                <div class="info-content-scroll">
                                    <div class="chapter-item" :class="{'chap`ter-item-active':play_index == index}" v-for="(item,index) in obj.videoList" @click="play_video(item,index)">
                                        <div class="chapter-num">
                                            <img v-if="play_index == index" src="@/static/images/play.gif" alt="">
                                            <span v-else>{{ index+1 }}</span>
                                            
                                        </div>
                                        <div class="ivu-tooltip">
                                            <div class="chapter-title">{{ item.videoTitle || '' }}</div>
                                        </div>
                                        <div class="chapter-info">
                                            <div>{{item.videoLength ? changSecond(item.videoLength||'00:00') : '00:00'}} | {{(item.viewedNum || 0)>10000?changeUnits(item.viewedNum):(item.viewedNum || 0)}}次播放</div>
                                            <span class="chapter-sample iconfont icon-mima fw700" v-if="pay_ok"> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import {courseOpenTime,listParentComments,downloadFile,selectByIdBaseVideo,videoCommentsDel,addDownLoadNum,addDownLoadNum1,sourc,sourc2,videoDetails,praise,addVideoCollection,listComments,
	comment,selectMidRecord,addMidRecord,payOpenMember,getVIPPrice,payOpen,orderSettingGet} from '@/apiUrl/index.js'
import { mapGetters } from "vuex"
export default {
    computed: {
        ...mapGetters(["userInfo", "allUserInfo"]),
    },
    data() {
        return {
                activeName:'video',
                comment_input:'', // 评论内容
                courseIndex:0, // 课程
                activeTab:0,
                show_currentTime:'00:00', 
                show_duration:'00:00',
                progress_value:'100',
                percentage:0,
                player:'',
                showvolume:false,
                play_type:false,
                current:0,
				evaluate_buyer_val:'',
				sourc,sourc2,
				num:0,
				bottom:'-150rpx',
				show:false,
				fixed:false,
				fixed1:false,
				id:'',
				obj:{},
				memberPhotoReport:null,
				reportMsg:{},
				videoMsg:{},
				
				
				video_parentId:'',
				video_current:1,
				video_list:[],
				video_isOK:true,
				replyId:'',
				content:'',
				nickName:'说点什么...',
				video_id:'',
				report_parentId:'',
				report_current:1,
				report_list:[],
				report_isOK:true,
				report_replyId:'',
				report_content:'',
				report_nickName:'说点什么...',
				videoContext: {},
				
				
				text1:'VIP畅听必有我师350+本书籍讲解',
				text2:'成为会员后下载报告',
				vip_ok:false,
				
				videoTrial:30,
				
				suffix:'',
				version:this.$store.state.version,
				show_img:true,
				time_ok:true,
				ps_text:'视频',
				pageX:0,
				play_index:0,
				play_time:0,
				currentTime:0,
				play_ok:false,
				
				current1:'0',
				type:1,
				isOK:true,
				money:0,
				pay_ok:false,
				videoReport:{},
				urlType:'video', // video 视频 audio音频
				viewType:1, // 1 音视频 2 文件浏览
                player:'',
                play_ok:true,
        };
    },
    created() {
        let {id,mode} = this.$route.query;
        this.id = id || '';
        this.get_selectMidRecord();
    },
    methods: {
        report_click_user(item,video_list) {
            if(item.showReply) {
                this.$set(item,'showReply',false);
                this.report_replyId = '';
                this.user_id = '';
                this.report_nickName = '说点什么...';
                this.report_content = '';
            } else {
                this.$set(item,'showReply',true);
                video_list.forEach(i => {
                    if(i.id != item.id) {
                        this.$set(i,'showReply',false)
                    }
                })
                this.report_replyId = item.id;
                this.user_id = item.evaluatorsId;
                this.report_nickName = '对 '+ item.evaluatorsName+'说';
                this.report_content = '';
            }
        },
        // 报告评论
        report_publish:function(report_content){
            if(!report_content) {
                this.$msg('评论内容不能为空',2)
                return
            }
            comment({
                content:report_content,
                evaluatorsId:this.userInfo.id,
                objType:2,
                replyId:this.report_replyId,
                objId:this.memberPhotoReport.id || '',
            }).then((e)=>{
                if (e.code == 200) {
                    this.$msg('评价成功!')
                    this.report_current = 1;
                    this.report_content = '';
                    this.content = '';
                    this.reportMsg.commentCount++;
                    this.get_report_evaluate();
                }else{
                    this.$msg(e.message);
                }
            })
        },
        download_report(e,id){//下载报告
            var el = this;
            if(this.pay_ok){
                this.show_modal();
                return
            }
            console.log('下载中...')

			this.$downloadFile(e,'')
            addDownLoadNum({
                id,
                form:'form'
            }).then(res => {
                
            })	
		},
        show_modal(){ 
            var el = this;
            this.$confirm(`该报告为付费报告,需支付${this.obj.price || 0}元后观看或下载`, '提示', {
                confirmButtonText: '去购买',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    console.log('去购买')
            })
        },
        preview_report(e){//预览报告
            var el = this;
            if(this.pay_ok){
                this.show_modal();
                return
            }
            console.log('打开中...')
            this.$downloadFile(e,'')
        },
        goBack() {
            console.log('go back');
            this.$router.back();
        },
        show_modal() {
            this.$confirm(`该系列课程为付费课程,需支付${this.obj.price || 0}元后观看或下载`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    console.log('去购买会员')
                })      
        },
        play_video(item,index){
            if(this.pay_ok){
                var el = this;
                this.show_modal();
                return
            }
            if(!item.url){
                this.$msg('本节课程正在录制中，请稍后观看。')
                return
            }
            this.pay_isOK = false;
            this.obj.videoList[this.play_index].play_time = this.currentTime;
            this.play_index = index;
            this.video_id = this.obj.videoList[index].id;
            this.play_time = this.obj.videoList[index].play_time;
            this.set_play(this.obj.videoList[index].id)
            item.viewedNum++;
            if(!this.obj.videoList[index].son){
                this.obj.url = item.url;
            }else{
                this.son_play_index = 0;
                this.obj.url = this.obj.videoList[index].son_list[0]
            }
            if(this.checkFile(this.obj.url)) {
                this.urlType = 'video'
            } else {
                this.urlType = 'audio'
                this.showAudio = false;
                setTimeout(() => {
                    this.showAudio = true;
                })
            }
            
            this.obj.photoUrl = item.photoUrl;
            this.obj.videoTitle = item.videoTitle;
            this.obj.viewedNum = item.viewedNum;
            this.obj.content = item.content;
            setTimeout(()=>{
                // this.player = '';
                // this.getvideo(this.obj.photoUrl,this.obj.url);
                var player = this.$video('myVideo');
                player.src({
                    src:this.obj.url,
                    poster:this.obj.photoUrl
                })
            },200)
        },
        set_play(id){
            courseOpenTime({id,form:'form'}).then((res)=>{
                
            })
        },
        changeUnits(num) {
            let val = num/10000;
            val = val.toFixed(1);
            return val + '万'
        },
        changSecond(e) { //转换成秒
            return this.$secondToMinutes(e)
        },
        get_selectMidRecord(){
            selectMidRecord({mid:this.userInfo.id,courseId:this.id}).then((res)=>{
                if(res.code==200){
                    if(res.data){
                        this.play_time = res.data.videoLength;
                        this.video_id = res.data.videoId;
                    }
                    this.getdata();
                }else{
                    // this.$msg(res.message)
                }
            })
        },
        delEva(id,index,list) {
            this.$confirm('你确定要删除该评论吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    videoCommentsDel({
                        id
                    }).then(res =>{
                        if(res.code == 200) {
                            this.$msg('删除成功！')
                            list.splice(index,1);
                        } else {
                            this.$msg(res.message)
                        }
                    })
            })      
		},
        // 发表评论
        publish:function(content,item){
            if(!content) {
                this.$msg('评论内容不能为空')
                return
            }
            comment({
                content:content,
                evaluatorsId:this.userInfo.id,
                objType:1,
                replyId:this.replyId,
                objId:this.id
            }).then((e)=>{
                if (e.code == 200) {
                    this.$msg('评价成功!')
                    this.video_current = 1;
                    this.content = '';
                    this.comment_input = '';
                    this.videoMsg.commentCount++;
                    if(this.replyId) {
                        this.moreComment(this.replyId,1);
                        this.$set(item,'showReply',false);
                    } else {
                        this.get_video_evaluate();
                        this.comment_input = '';
                    }
                    
                }else{
                    this.$msg(res.message);
                }
            })
        },
        reply(item,video_list) {
            if(item.showReply) {
                this.$set(item,'showReply',false);
                this.replyId = '';
                this.user_id = '';
                this.nickName = '说点什么...';
                this.content = '';
            } else {
                this.$set(item,'showReply',true);
                video_list.forEach(i => {
                    if(i.id != item.id) {
                        this.$set(i,'showReply',false)
                    }
                })
                this.replyId = item.id;
                this.user_id = item.evaluatorsId;
                this.nickName = '对 '+ item.evaluatorsName+'说';
                this.content = '';
            }
           
        },
        moreComment(id,type) { // 查看更多评论
            listParentComments({
                objType:type,
                objId:type == 1 ? this.id : this.memberPhotoReport.id,
                current:1,
                parentId:id,
                size:"999"
            }).then(res => {
                if(res.code==200){
                    var arr = res.data.list;
                    if(type == 1) { // 视频
                        this.video_list.some(item => {
                            if(item.id == id) {
                                this.$set(item,'replyList',[...arr])
                                return true
                            }
                        })
                    } else {// 报告
                        this.report_list.some(item => {
                            if(item.id == id) {
                                this.$set(item,'replyList',[...arr])
                                return true
                            }
                        })
                    }
                }else{
                    this.$msg(res.message);
                }
            })
        },  
        judge(){
            // text1:'VIP畅听必有我师350+本书籍讲解',
            // text2:'成为会员后下载报告',
            if(this.obj.vipSet==3){//3就会员
                if(this.userInfo.bywsMember!=3){//没达到该等级
                    this.vip_ok = true;
                    this.text1 = '请开通三级会员方可观看'
                    this.text2 = '请开通三级会员方可下载报告'
                }else{//达到了3级
                    this.vip_ok = false;
                }
            }else if(this.obj.vipSet==2){//2级会员
                if(this.userInfo.bywsMember!=3&&this.userInfo.bywsMember!=2){//没达到该等级
                    this.vip_ok = true;
                    this.text1 = '升级VIP服务享受为企业赋能的全套教程'
                    this.text2 = '请开通二级会员方可下载报告'
                }else{//达到了2级或者3级
                    this.vip_ok = false;
                }
            }else if(this.obj.vipSet==1){//1级会员
                if(this.userInfo.bywsMember!=3&&this.userInfo.bywsMember!=2&&this.userInfo.bywsMember!=1){//没达到该等级
                    this.vip_ok = true;
                    this.text1 = '开通VIP全部课程免费听'
                    this.text2 = '成为会员后下载报告'
                }else{//是会员
                    this.vip_ok = false;
                }
            }else{//无会员商品
                this.vip_ok = false;
            }
        },
        get_report_evaluate(){//报告评论-------
            if(!this.memberPhotoReport)return
            listComments({
                evaluatorsId:this.userInfo.id,
                objType:2,
                objId:this.memberPhotoReport.id,
                size:"12",
                current:this.report_current,			
            }).then((res)=>{
                if(res.code==200){
                    var arr = res.data;
                    if(this.report_current==1){
                        this.report_list = arr;
                    }else{
                        this.report_list = this.report_list.concat(arr);
                    }
                    if(arr.length<12){
                        this.report_isOK = false;
                    }else{
                        this.report_isOK = true;
                        this.report_current++;
                    }
                }else{
                    this.$msg(res.message);
                }
            })
        },
        get_video_evaluate(){//视频评论-------
            listComments({
                evaluatorsId:this.userInfo.id,
                objType:1,
                objId:this.id,
                size:"12",
                current:this.video_current,
            }).then((res)=>{
                if(res.code==200){
                    var arr = res.data;
                    if(this.video_current==1){
                        this.video_list = arr;
                    }else{
                        this.video_list = this.video_list.concat(arr);
                    }
                    if(arr.length<12){
                        this.video_isOK = false;
                    }else{
                        this.video_isOK = true;
                        this.video_current++;
                    }
                }else{
                    this.$msg(res.message);
                }
            })
        },
        checkFile(name) { // 判断是视频文件还是音频文件
            let filename = name; //文件路径地址
            let index1 = filename.lastIndexOf(".");
        
            let index2 = filename.length;
            let postf = filename.substring(index1, index2); //获取文bai件后缀名duzhi
            // console.log(postf.split('?'))
            postf = postf.split('?')[0];
            postf = postf.toLowerCase();
        
            //判断文件后缀名是否等于视频文件的后缀名
            if (postf===".avi"||postf===".mp4"||postf===".rmvb") {
                return true
            }else{
                return false
            }
        },
        tran(about_us) {
            if (!about_us) return ''
            about_us = about_us.replace(/<img src="/g,
                    '<img style="max-width:100%;height:auto;display:block;margin-bottom:10px;border-radius: 4px;margin:0 auto;" src="'
                    )
                .replace(/<li/g, '<li style="overflow: hidden;"');
            return about_us
        },
        show_model(val){ // 收藏
				if(val=='video'){//视频点赞
					var id = this.id;
					var status = this.videoMsg.collectionStatus?'1':'0'
					var type = 1;
					this.ps_text = '视频';
				}else{//报告点赞
					var id = this.memberPhotoReport.id;
					var status = this.reportMsg.collectionStatus?'1':'0'
					var type = 2;
					this.ps_text = '报告';
				}
				addVideoCollection({mid:this.userInfo.id || '',objId:id,status,objType:type}).then((res)=>{
					if(res.code==200){
						if(val=='video'){
							res.data==1?this.videoMsg.collectionStatus=true:this.videoMsg.collectionStatus=false
						}else{
							res.data==1?this.reportMsg.collectionStatus=true:this.reportMsg.collectionStatus=false
						}
						if(res.data==1){
							if(val=='video'){
								this.videoMsg.collectionCount++
							}else{
								this.reportMsg.collectionCount++
							}
						}else{
							if(val=='video'){
								this.videoMsg.collectionCount--;
							}else{
								this.reportMsg.collectionCount--;
							}
							this.$msg('取消收藏成功!')
						}
					}else{
						this.$msg(res.message)
					}
				})
		},
        fabulous(val){ // 点赞
            if(val=='video'){//视频点赞
                var id = this.id;
                var praiseType = 1
            }else{//报告点赞
                var id = this.memberPhotoReport.id;
                var praiseType = 2
            }
            praise({mid:this.userInfo.id || '',objId:id,objType:praiseType}).then((res)=>{
                if(res.code==200){
                    if(val=='video'){
                        res.data==1?this.videoMsg.praiseStatus=true:this.videoMsg.praiseStatus=false
                    }else{
                        res.data==1?this.reportMsg.praiseStatus=true:this.reportMsg.praiseStatus=false
                    }
                    if(res.data==1){
                        if(val=='video'){
                            this.videoMsg.praiseCount++
                        }else{
                            this.reportMsg.praiseCount++
                        }
                        this.$msg('点赞成功!')
                    }else{
                        if(val=='video'){
                            this.videoMsg.praiseCount--;
                        }else{
                            this.reportMsg.praiseCount--;
                        }
                        this.$msg('取消点赞成功!')
                    }
                }else{
                    this.$msg(res.message)
                }
            })
        },
        changeslider(val) {
            console.log('val',val)
            let video = document.getElementById("myVideo_html5_api");
            // this.percentage = val/(video.offsetWidth-16)*100;
            this.percentage = val;
            console.log('percentage',this.percentage)
            video.currentTime = this.percentage*video.duration/100;
        },
        formatTooltip() {
            return this.show_currentTime
        },
        getdata(){
				videoDetails({id:this.id,form:'form',mid:this.userInfo.id || ''}).then((res)=>{
					if(res.code==200){
						this.obj = res.data.video;
						this.viewType = this.obj.viewType || 1;
                        this.pay_ok = !res.data.payResult;
						if(this.obj.videoList){
							this.obj.videoList.forEach((v,i)=>{
								v.play_time = 0;
								v.isOK = false;
								if(v.url){
									var arr = v.url.split(',');
									if(arr.length>1){
										v.son_list = arr;
										v.son = true;
									}else{
										v.son = false;
									}
								}
								if(v.id==this.video_id){
									this.play_index = i;
								}
							})
						}
						if(this.obj.videoList&&this.obj.videoList.length&&this.obj.videoList.length>=this.play_index){
							this.obj.url = this.obj.videoList[this.play_index].url;
							this.video_id = this.obj.videoList[this.play_index].id;
							this.obj.photoUrl = this.obj.videoList[this.play_index].photoUrl;
							this.obj.videoTitle = this.obj.videoList[this.play_index].videoTitle;
							this.obj.viewedNum = this.obj.videoList[this.play_index].viewedNum;
							this.obj.content = this.obj.videoList[this.play_index].content;
						}else{
							this.obj.url = this.obj.videoList[0].url;
                            console.log('123',this.obj.videoList[this.play_index].id)
							this.video_id = this.obj.videoList[this.play_index].id;
							this.obj.photoUrl = this.obj.videoList[0].photoUrl;
							this.obj.videoTitle = this.obj.videoList[0].videoTitle;
							this.obj.viewedNum = this.obj.videoList[0].viewedNum;
							this.obj.content = this.obj.videoList[0].content;
						}
						if(!this.obj.url){
                            this.$alert('本节课程正在录制中，请稍后观看。', '提示', {
                                confirmButtonText: '确定'
                            });
						}
						if(this.obj.showVideoStatus == 3){
							this.version = true;
						}
						if(this.checkFile(this.obj.url)) {
							this.urlType = 'video'
						} else {
							this.urlType = 'audio'
						}
						this.videoReport = res.data.videoReport;
						this.videoTrial = this.obj.videoTrial;
						if(this.obj.viewType == 2) { // 文件浏览
							this.memberPhotoReport = this.obj;
							this.videoReport = this.obj
							this.videoReport.reportText = this.obj.videoTitle || '';
							this.current =1;
						} else {
							this.memberPhotoReport = res.data.videoReport;
						}
						
						this.reportMsg = res.data.reportMsg;
						this.videoMsg = res.data.videoMsg;
						this.pay_ok = !res.data.payResult;
						if(this.userInfo.id) {
							this.get_video_evaluate();
							if(this.obj.viewType == 1) { // 音视频类型
								this.get_report_evaluate();
							}
						}
                       
						this.getvideo(this.obj.photoUrl,this.obj.url)
						
						this.judge();
					}else{
						// this.$msg(res.message)
					}
				})
			},
        getvideo(poster,url) {
            let el = this;
            setTimeout(()=>{
                this.player = this.$video(document.getElementById('myVideo'), {
                    bigPlayButton: true,
                    textTrackDisplay: true,
                    posterImage: true,
                    errorDisplay: true,
                    // fluid: true, // 自适应宽高
                    width:820,
                    height:461,
                    language: 'zh-CN', // 设置语言
                    controls: true,
                   
                    playbackRates: [0.5, 1, 1.5, 2],
                        controlBar: { // 设置控制条组件
                         //这里可以自己控制各小组件的顺序和显示方式
                        children: [
                        {name: 'playToggle'}, // 播放按钮
                        {name: 'currentTimeDisplay'}, // 当前已播放时间
                        {name: 'progressControl'}, // 播放进度条
                        {name: 'durationDisplay'}, // 总时间
                        {name: 'audioTrackButton'},
                        { // 倍数播放
                            name: 'playbackRateMenuButton',
                            'playbackRates': [0.5, 1, 1.5, 2, 2.5]
                        },
                        {
                            name: 'volumePanel', // 音量控制
                            inline: false, // 不使用水平方式
                        },
                        ],
                        PictureInPictureToggle:true,  //画中画
                        FullscreenToggle: true // 全屏
                    },
                    poster: poster,//视频封面
                    sources:[ // 视频来源路径
                        {
                            src: url,
                        }
                    ]
                    }, function (){
                    console.log('视频可以播放了',this);
                    if(el.urlType == 'audio') {
                        let vjs_poster = document.querySelectorAll('.vjs-poster');
                        if(vjs_poster.length>0) {
                            vjs_poster.forEach(item => {
                                item.style.display = 'inline-block';
                            })
                        }
                    }
                    });
                let video = document.getElementById("myVideo_html5_api");
                // video.play();
            }, 0);
            setTimeout(()=>{
                this.add_video_listen();
            },200)
        },  
        set_percentage(e){//点击进度条设置播放指定时间
            // let video = document.getElementById("myVideo_html5_api");
            // this.percentage = e.layerX/video.offsetWidth*100;
            // video.currentTime = this.percentage*video.duration/100;
        },
        add_video_listen(){//给当前视频添加进度条监听
            let video = document.getElementById("myVideo_html5_api");
            video.addEventListener("play",this.video_listen,false);
            video.addEventListener("ended",this.video_ended,false);
        
        },
        video_listen(e){//视频播放中监听
            e.preventDefault();
            let video = document.getElementById("myVideo_html5_api");
            let ele = document.documentElement;
            if(!this.play_ok) {
                return false
            }
            this.play_ok = false
            setTimeout(() => {
                if(this.pay_ok){
                // ele.exitFullScreen();
                this.exitFullscreen();
                video.currentTime = 0;
                video.pause();
                this.play_ok = true;
                this.show_modal();
            }
            },1000) 
            
        },
        video_ended(){//视频播放完毕监听
            console.log('触发了')
            if(this.obj.videoList[this.play_index].son){//有子级播放下一个子级
					if((this.son_play_index+1)==this.obj.videoList[this.play_index].son_list.length){
						if((this.play_index+1)==this.obj.videoList.length){//如果是最后一条,播放完不播放了
							return
						}
						if(!this.obj.videoList[this.play_index+1].url){
							this.videoContext.pause();
							this.videoContext.seek(0);
							this.play_time = this.obj.videoList[this.play_index].play_time = this.currentTime = 0;
							this.$msg('本节课程正在录制中，请稍后观看。')
							return
						}
						this.play_index++;
						this.play_video(this.obj.videoList[this.play_index],this.play_index)
					}else{
						this.son_play_index++;
						this.obj.url = this.obj.videoList[this.play_index].son_list[this.son_play_index];
						if(this.checkFile(this.obj.url)) {
							this.urlType = 'video'
						} else {
							this.urlType = 'audio'
						}
						setTimeout(()=>{
							this.videoContext.play();
						},200)
					}
				}else{//没有子级
					if((this.play_index+1)==this.obj.videoList.length){//如果是最后一条,播放完不播放了
						return
					}
					if(!this.obj.videoList[this.play_index+1].url){
						this.videoContext.pause();
						this.videoContext.seek(0);
						this.play_time = this.obj.videoList[this.play_index].play_time = this.currentTime = 0;
						this.$msg('本节课程正在录制中，请稍后观看。')
						return
					}
					this.play_index++;
					this.play_video(this.obj.videoList[this.play_index],this.play_index)
				}
        },

        //退出全屏
        exitFullscreen() {
            var de = document;
            if (de.exitFullscreen) {
                de.exitFullscreen();
            } else if (de.mozCancelFullScreen) {
                de.mozCancelFullScreen();
            } else if (de.webkitCancelFullScreen) {
                de.webkitCancelFullScreen();
            }
            }
        }
}

</script>

<style lang="less" scoped>
.main_content {
    box-sizing: border-box;
    margin-top: 15px;
}
.middle_content {
    max-width: 1208px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}
.edu-main {
    background-color: #fff;
    padding: 20px 36px 26px;
    margin-bottom: 10px;
}
.middle_right {
    position: relative;
    margin-left: 10px;
    width: 338px;
    flex: 0 0 338px;
    box-sizing: border-box;
    // background-color: #fff;
}
.container-player {
    height: 461px;
    width: 789px;
    position: relative;
    img {
        width: 100%;
        height: 100%;
    }
}

.video-js{
    width: 100%;
    height: 100%;
}
// .vjs-modal-dialog-content{
//     display: none;
// }
// .vjs-error .vjs-error-display:before{
//     display: none;
// }
.video_operate{
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    position: relative;
    left: 0;
    bottom: 0;
    background-color: #000;
}
.operate_left{
    line-height: 40px;
    color: #fff;
    margin-left: 20px;
    .video_schedule{
        margin-left: 10px;
        font-weight: 700;
        font-size: 15px;
    }
}
.operate_right{
    line-height: 40px;
    color: #fff;
    margin-right: 30px;
}
.operate_right_item{
    font-size: 15px;
    margin-left: 20px;
    cursor: pointer;
}
.sound {
    position: relative;
}
.progress_box {
    // background: hsla(0,0%,8%,.9);
    // background: #fff;
    border-radius: 2px;
    bottom: 41px;
    height: 100px;
    top:-103px;
    left: 50%;
    margin-left: -21px;
    position: absolute;
    width: 36px;
    z-index: 99999999999999999;
}
.progress{
    position: absolute;
    width: 100%;
    left: 0;
    top: -13px;
    padding: 0 8px;
}
.title-main {
    display: flex;
    margin-bottom: 10px;
    padding-top: 20px;
    .title {
        height: 32px;
        font-size: 24px;
        font-weight: 700;
        color: #1c1c28;
        line-height: 32px;
        flex: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    button {
        line-height: 1.5;
        outline: 0;
        font-size: 16px;
        display: inline-block;
        margin-bottom: 0;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
        background-image: none;
        white-space: nowrap;
        user-select: none;
        height: 32px;
        padding: 0 15px;
        font-size: 14px;
        border-radius: 4px;
        transition: color .2s linear,background-color .2s linear,border .2s linear,box-shadow .2s linear;
        color: #515a6e;
        background-color: #fff;
        border: 1px solid #f2f2f5; 
        i {
            font-size: 12px;
        }
    }
}
.edu-detail {
    display: flex;
    gap: 24px;
    margin-bottom: 10px;
    .edu-detail-item {
        height: 20px;
        line-height: 20px;
        color: #555770;
        font-size: 14px;
        i {
            font-size: 16px;
        }
    }
}
.detail-desc {
    font-size: 14px;
    color: #555770;
    line-height: 26px;
}
.column-price-card {
    display: inline-flex;
    gap: 12px;
    margin-top: 10px;
}
.column-buy-btn {
    border-radius: 18px;
    height: 36px;
    line-height: 36px;
    font-weight: 700;
    text-align: center;
    font-size: 14px;
    color: #fff;
    position: relative;
    min-width: 127px;
    padding: 0 32px;
    cursor: pointer;
}
.column-buy-btn.single {
    background-color: #fc5531;
    vertical-align: middle;
}
.column-buy-btn.single b {
    font-size: 16px;
    padding: 0 4px;
}
.column-buy-btn.single del {
    font-size: 12px;
    color: #ccccd8;
}
.column-buy-btn.vip {
    color: #f8e3c3;
    background-color: #444555;
}
/* tab栏 */
.edu-tab {
    height: 48px;
    background: #fff;
    border-radius: 2px 2px 0 0;
    padding: 0 24px;
    display: flex;
    gap: 48px;
    border-bottom: 1px solid #f0f0f2;
    margin-bottom: 10px;
}
.edu-tab-item{
    color: #777888;
    font-size: 14px;
    font-weight: 700;
    line-height: 48px;
    cursor: pointer;
}
.edu-tab-item.active {
    color: #222226;
    border-bottom: 2px solid #3a4156;
}
/* 课程概述 */
.edu-detail-wapper {
    background-color: #fff;
    padding: 20px;
    .edu-detail-content {
        word-break: break-all;
        margin-bottom: 20px;
    }
    .content-title {
        font-size: 16px;
        font-weight: 700;
        color: #1c1c28;
        line-height: 28px;
        margin-bottom: 20px;
    }
    .content-html {
        line-height: 1.5;
    }
}
/* 留言互动 */
.comment_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f7f8f9;
    margin-top:15px;
    margin-bottom:15px;
    input {
        background-color: #f7f8f9;
        border: none;
        height: 40px;
        width: 100%;
        outline: none;
        padding: 0 15px;
    }
    input:focus {
        border: none;
    }
}
.comments-detail {
    margin-bottom: 20px;
    padding-top: 10px;
    border-bottom: 1px solid #f2f2f5;
}
.edu-comment-wrapper {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    .comment-title {
        font-size: 16px;
    }
    .comments-detail-user {
        display: flex;
        align-items: center;
        img {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            cursor: pointer;
        }
        .comments-detail-user-info {
            font-weight: 700;
            font-size: 16px;
            margin-left: 15px;
            cursor: pointer;
        }
    }
    .comments-detail-content {
        font-size: 14px;
        color: #555770;
        line-height: 22px;
        padding-left: 52px;
        margin-bottom: 10px;
        word-break: break-all;
    }
    .comments-detail-opt {
        display: flex;
        padding-left: 52px;
        gap: 16px;
        color: #777888;
        line-height: 20px;
        margin-bottom: 16px;
        .comments-detail-opt-item {
            display: flex;
            align-items: center;
            cursor: pointer;
            gap: 4px;
        }
    }
    .sub-comments {
        margin-left: 52px;
        margin-bottom: 32px;
        padding: 20px;
        border-bottom: none;
        background: #f7f8fa;
        .sub-comments-item {
            border-bottom: 1px solid #f2f2f5;
            margin-bottom: 15px;
        }
        .comments-detail-user {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 14px;
            margin-bottom: 0;
            img {
                width: 32px;
                height: 32px;
                border-radius: 50%;
            }
            .user-name {
                font-size: 15px;
                font-weight: 700;
                color: #1c1c28;
            }
            .user-tips {
                display: inline-block;
                width: 48px;
                height: 20px;
                font-size: 14px;
                color: #0076ff!important;
                line-height: 20px;
                text-align: center;
                background: #e5f1ff;
                border-radius: 2px;
            }
        }
        .comments-detail-content {
            font-size: 14px;
            color: #555770;
            line-height: 22px;
            padding-left: 42px;
            margin-bottom: 10px;
            word-break: break-all;
        }
    }
}
/* 右侧课程列表 */
.right-info {
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    .title {
        border-bottom: 1px solid #f2f2f5;
        padding: 10px 20px;
        line-height: 20px;
        color: #1c1c28;
        font-weight: 700;
    }
    .info-content {
        padding: 10px 20px;
        .info-content-hidden {
            height: 798px;
            padding: 0px;
            position: relative;
            overflow: hidden;
        }
        .info-content-scroll {
            position: relative;
            box-sizing: border-box;
            height: 100%;
            overflow: hidden auto;
            // margin-right: -17px;
        }
    }
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.chapter-item {
    height: 76px;
    padding: 12px 16px 12px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fafafc;
    border-radius: 4px;
    border: 1px solid #f2f2f5;
    position: relative;
    margin-bottom: 4px;
    cursor: pointer;
    .chapter-num {
        position: absolute;
        left: 0;
        top: 16px;
        width: 40px;
        line-height: 16px;
        font-size: 14px;
        color: #8f90a6;
        text-align: center;
        img {
            width: 16px;
            height: 18px;
        }
    }
    .chapter-title {
        height: 24px;
        font-size: 14px;
        color: #1c1c28;
        line-height: 24px;
        overflow: hidden;
        -webkit-line-clamp: 1;
    }
    .chapter-info {
        height: 16px;
        font-size: 12px;
        color: #8f90a6;
        line-height: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .chapter-sample {
        float: right;
        font-size: 16px;
        color: #555666;
        line-height: 16px;
    }
}
.right-info .chapter-item-active {
    border-color: #3e7bfa;
    background: #fff;
}
.right-info .chapter-item:hover {
    border-color: #3e7bfa;
    background: #fff;
}
/* 播放器 */
/deep/ .vjs-paused .vjs-big-play-button,
/deep/ .vjs-paused.vjs-has-started .vjs-big-play-button {
    display: block;
}
/deep/ .video-js .vjs-big-play-button {
  background-color: rgba(0,0,0,0.6);
  font-size: 4em;
  border-radius: 50%;
  height: 1.5em !important;
  line-height: 1.5em !important;
  width: 1.5em !important;
  left: calc(103%/2);
  top: calc((100%)/2);
  transition: transform 0.5s ease;
}

/deep/ .video-js .vjs-big-play-button:hover{
  transform: scale(1.2,1.2);
}
/deep/ .video-js.vjs-playing .vjs-tech {
    pointer-events: auto;
}
/deep/ .video-js .vjs-time-control{display:block;}
/deep/ .video-js .vjs-remaining-time{display: none;}
/deep/ .video-js .vjs-play-progress:before {
    top: 50%;
    transform: translateY(-50%);
}
.ml32 {
    margin-left: 52px;
}
.fetch-more-comment {
    margin-top: 12px;
    font-size: 13px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #515767;
    background-color: #f7f8fa;
    margin-left: 52px;
    margin-bottom: 32px;
}
.fetch-more-comment:hover {
    background-color: #f2f3f5;
}
.pageHeader {
    background-color:#fff;
    padding:15px;
    padding-bottom:0;
}
</style>